import React from "react";
import { css } from "emotion";
import moment from "moment";

const Box = ({ children, borderColor, bgColor, color }) => (
  <div
    className={css`
      border: 1px solid ${borderColor ? borderColor : "rgba(0, 0, 0, 0.15)"};
      color: ${color ? color : "rgba(60, 60, 60, 1)"};
      background-color: ${bgColor ? bgColor : "transparent"};
      opacity: 0.7;
      border-radius: 4px;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.6rem;
      margin-bottom: 15px;
      transition: opacity 0.2s ease;

      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    `}
  >
    {children}
  </div>
);

const Section = ({ name, times }) => (
  <div>
    <div
      className={css`
        font-weight: 600;
        margin-bottom: 8px;
      `}
    >
      {name}
    </div>
    <div
      className={css`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 20px;
      `}
    >
      {times.map(time => (
        <Box key={time} bgColor="rgb(35, 118, 229)" color="#FFF">
          {moment()
            .set({ hour: 0, minute: 0, second: 0 })
            .add(time, "minutes")
            .format("h:mm a")}
        </Box>
      ))}
      <Box borderColor="rgb(18, 173, 253)" color="rgb(18, 173, 253)">
        <i className="material-icons">notifications</i>
        &nbsp;Notify Me
      </Box>
    </div>
  </div>
);

const Times = () => (
  <div
    className={css`
      > div:not(:first-child) {
        margin-top: 12px;
      }
    `}
  >
    <Section name="Morning" times={[480, 555, 630, 645, 660]} />
    <Section name="Afternoon" times={[]} />
  </div>
);

export default Times;
