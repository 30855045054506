import React, { Component } from "react";
import Header from "./header";
import Steps from "./steps";
import Appointment from "./appointment";
import { apiGet } from "../api";
import { css } from "emotion";

class Booker extends Component {
  state = { loading: true, currentStep: 0 };

  componentDidMount() {
    apiGet(`book_appointment?orgId=${this.props.orgId}`).then(({ data }) =>
      this.setState({ org: { name: data.name }, loading: false })
    );
  }

  render() {
    const { loading, org, currentStep } = this.state;

    if (loading || !org) {
      return false;
    }

    return (
      <div>
        <Header name={org.name} />
        <Steps currentStep={currentStep} />
        <div
          className={css`
            padding: 23px 30px;
          `}
        >
          {currentStep === 0 && <Appointment />}
        </div>
        <div
          className={css`
            font-size: 1.45rem;
            padding: 0 15px 15px;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <a
            href="https://vetspire.com"
            className={css`
              text-decoration: none;
              color: rgba(60, 60, 60, 0.6);
            `}
          >
            Powered by Vetspire
          </a>
        </div>
      </div>
    );
  }
}

export default Booker;
