import React from "react";
import { css } from "emotion";

const Steps = ({ currentStep }) => (
  <div
    className={css`
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      font-size: 1.8rem;

      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom-width: 3px;
        border-bottom-style: solid;
        padding: 10px;
      }
    `}
  >
    {["Appointment", "Your Details", "Your Pet", "Confirm"].map((n, idx) => (
      <div
        key={idx}
        className={css`
          font-weight: ${idx <= currentStep ? "700" : "600"};
          color: ${idx === currentStep
            ? "rgb(70, 136, 241)"
            : idx < currentStep
              ? "rgb(0, 0, 0)"
              : "rgba(0, 0, 0, 0.5)"};
          border-color: ${idx === currentStep
            ? "rgb(70, 136, 241)"
            : "transparent"};
        `}
      >
        {n}
        {idx < currentStep && (
          <i
            className="material-icons"
            style={{
              fontSize: "1.45rem",
              color: "rgb(73, 221, 177)",
              display: "inline-block",
              marginLeft: "5px"
            }}
          >
            check_circle
          </i>
        )}
      </div>
    ))}
  </div>
);

export default Steps;
