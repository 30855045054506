import React from "react";
import { css } from "emotion";

const Header = ({ name }) => (
  <div
    className={css`
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      font-weight: 700;
    `}
  >
    <div>Book your appointment at {name}</div>
  </div>
);

export default Header;
