import React, { Component } from "react";
import { css } from "emotion";

class DateSelect extends Component {
  render() {
    return <div />;
  }
}

export default DateSelect;
