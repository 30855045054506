import React from "react";
import { css, injectGlobal } from "emotion";
import Transition from "react-transition-group/Transition";
import Booker from "./booker";
import queryString from "../deps/queryString";

import "normalize.css";

injectGlobal`
  * {
    box-sizing: border-box;
  }

  html {
    font-family: "Source Sans Pro", "Helvetica", sans-serif;
    font-size: 62.5%;
    line-height: 1.4;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
  }

  body {
    font-size: 1.8rem;
  }
`;

function teardown() {
  window.parent.postMessage("teardown", "*");
}

const duration = 200;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0
};

const transitionStyles = {
  appearing: { opacity: 0 },
  appeared: { opacity: 1 },
  entered: { opacity: 1 }
};

const defaultModalStyle = {
  transition: `opacity ${duration}ms ease-in-out, transform ${duration}ms ease-in-out`,
  opacity: 0,
  transform: "translateY(-100px)"
};

const transitionModalStyles = {
  appearing: { opacity: 0, transform: "translateY(-100px)" },
  appeared: { opacity: 1, transform: "translateY(0)" },
  entered: { opacity: 1, transform: "translateY(0)" }
};

const App = () => {
  const search = window.location.search;
  const initialState = queryString.parse(search.slice(1));
  if (!initialState.orgId || initialState.orgId === "") {
    teardown();
    window.location.href = "https://vetspire.com/404";
  }

  return (
    <Transition in={true} appear={true} timeout={0}>
      {state => (
        <div
          className={css`
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(54, 62, 73, 0.8);
            padding: 30px 5px 5px 5px;
          `}
          style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
          onClick={() => teardown()}
        >
          <Transition in={true} appear={true} timeout={duration}>
            {state => (
              <div
                className={css`
                  width: 700px;
                  max-width: 100%;
                  margin: 0 auto;
                `}
                style={{
                  ...defaultModalStyle,
                  ...transitionModalStyles[state]
                }}
              >
                <div
                  className={css`
                    color: rgba(255, 255, 255, 0.7);
                    cursor: pointer;
                    margin-bottom: 5px;
                    text-align: right;
                  `}
                >
                  <i className="material-icons">close</i>
                </div>
                <div
                  className={css`
                    background-color: #fff;
                    border-radius: 8px;
                    min-height: 60px;
                    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
                    transition: height 0.2s ease;
                  `}
                  onClick={e => e.stopPropagation()}
                >
                  <Booker
                    orgId={initialState.orgId}
                    locationId={initialState.locationId}
                  />
                </div>
              </div>
            )}
          </Transition>
        </div>
      )}
    </Transition>
  );
};

export default App;
