import React, { Component } from "react";
import { css } from "emotion";

class Select extends Component {
  render() {
    return (
      <div
        className={css`
          border: 1px solid rgba(0, 0, 0, 0.15);
          border-radius: 4px;
          padding: 8px 13px;
          font-weight: 700;
          display: inline-flex;
          align-items: center;
          position: relative;
          transition: background-color 0.2s ease;

          &:hover {
            background-color: rgba(18, 173, 253, 0.1);
            cursor: pointer;
          }
        `}
      >
        {this.props.icon && (
          <i
            className="material-icons"
            style={{ fontSize: "1.8rem", marginRight: "7px" }}
          >
            {this.props.icon}
          </i>
        )}
        {this.props.options.find(o => o.value === this.props.value).label}
        <i
          className="material-icons"
          style={{
            color: "rgba(0, 0, 0, 0.7)"
          }}
        >
          keyboard_arrow_down
        </i>
      </div>
    );
  }
}

export default Select;
