import React from "react";
import { css } from "emotion";
import Select from "../components/select";
import DateSelect from "./dateSelect";
import Times from "./times";

const Appointment = () => (
  <div>
    <div
      className={css`
        color: rgba(60, 60, 60, 0.7);
        font-size: 1.6rem;
      `}
    >
      We look forward to welcoming you at Manchaca soon. Please select the most
      convenient appointment or give us a call at (555) 241-4294 to speak with
      one of our concierges.
    </div>
    <div
      className={css`
        margin: 20px 0;
        > div {
          margin-right: 20px;
        }
      `}
    >
      <Select
        value="Wellness"
        options={[
          { value: "Wellness", label: "Wellness Visit" },
          { value: "Sick", label: "Sick Visit" }
        ]}
        icon="local_hospital"
      />
      <Select
        value={1}
        icon="pets"
        options={[
          { value: 1, label: "1 pet" },
          { value: 2, label: "2 pets" },
          { value: 3, label: "3 pets" },
          { value: 4, label: "4 pets" },
          { value: 5, label: "5 pets" },
          { value: 6, label: "6 pets" }
        ]}
      />
    </div>
    <DateSelect />
    <Times />
  </div>
);

export default Appointment;
